import styled from 'styled-components';
import { Modal as AntModal } from 'antd';

export const Modal = styled(AntModal)`
  font-family: Roboto;
  h1 {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    color: #1B1464;
    margin-bottom: 22px;
    line-height: 26px;
    letter-spacing: 0em;
  }
  .closeButton {
    position: absolute;
    top: 10px;
    right: 0;
    background: transparent;
    border: none;
    box-shadow: none;
  }
  .close {
    font-size: 25px;
    color: #3742fa;
  }
  .highlight {
    color: rgb(255, 23, 68); /* Cambia este color según tu preferencia */
    font-weight: bold; /* Opcional: para resaltar aún más */
  }
  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
  }

    
  .Row-logoContent {
    position: absolute;
    top: -36px;
    left: 41%;
  }

  .ant-modal-content {
    height: auto;
    border-radius: 20px;
    text-align: center;
  }

  .ant-modal-body {
    padding: 60px 0px 0px;
    background: #EDEDED;
    border-radius: 20px;
  }

  .Row-ContentInfo--Rsvp {
    padding: 0 24px 10px;
  }

  .Col-ContentInfo--left {
    padding: 16px 0;
    text-align: initial;
    .subtitle {
        font-size: 18px;
        font-weight: 500;
        margin: 0px;
        color: #1B1464;
    }
  }

  .Col-ContentInfo--right {
    padding: 16px 0;
    text-align: end;
    p {
      font-weight: 500; 
      margin: 0px;
      line-height: 20px; 
      
    }

    .info {
      font-size: 16px;
      color: #748FAB;
    }

    .monto {
      font-size: 26px;
      color: #00E676;
    }

    .info-amount {
      font-size: 21px;.
      color: #404F5F;
    }

    .finishAmount {
      font-size: 26px;
      color: #00E676 ;
    }

    .color-finish {
      color: #00E676 !important;

    }
  }

  .border {
    border-bottom: 1px solid #BCBCBC;
  }


  .Row-ContentCupon {
        background: #FFFFFF;
        //border-radius: 20px;
        padding: 24px; 
        box-shadow: 0px 0px 20px 4px rgb(10 10 11 / 13%);
    }

    .Col-text {
        text-align: left;
        h1 {
          font-size: 18px;
          color: #1B1464;
          font-weight: 500;
          margin-bottom: 6px;
        }
        p {
            font-size: 17px;
            color: #748FAB;
            font-weight: 500;
            line-height: 20px;
            margin: 0px;
        }
    }

    .Col-ContentInput {
        padding: 10px 0px;
        label {
          position: absolute;
          z-index: 10;
          top: 6px;
          left: 20px;
          color: #1B1464;
        }
        .ant-input {
          height: 55px;
          font-weight: 500;
          color: #1B1464;
          font-size: 18px;
          border-radius: 10px 0 0 10px;
          border-color: #9daab8;
        }
    
        h1 {
            color: #1b1464;
        }
    }

    .Col-iconCupon {
        
        padding: 10px 0px;
        //display: grid;
        //align-content: center;
        //justify-content: end; 

        .ant-btn {
          border-color: #9daab8;
          display: grid;
          align-items: center;
          background: #ffff;
          height: 55px;
          border-left: 0;
          border-radius: 0 10px 10px 0;
          width: 100%;
        }

        .ant-btn:hover {
          border-color: #9daab8;
        }

        .ant-btn:focus {
          border-color: #9daab8;
        }
        
        svg {
            color: #ffff; 
            width: 27px;
            height: 27px;
        }
    }


////////////////////////////////////////////////////////////////

  .messageInfo {
      font-weight: 500;
      color: #3F5E80;
      white-space: pre-line
  }

  .messageSuccess {
    color: #000000;
    font-weight: bold;
  }

  .messageFailed {
    color: #f5222d;
    font-weight: bold;
  }
  .Row-button {
    margin: 20px 0;

    .ant-btn {
      background: #3742F;
      font-size: 20px;
      font-weight: 700;
      font-family: 'Roboto', sans-serif;
      color: #ffff;
      height: 60px;
      width: 100%;
      border: none;
      padding: 0 15px;
    }
    .Col-content--message {
        padding: 0px 24px 24px;
    }

    .Col-buttonLeft {
        text-align: left;
        .ant-btn {
            background: #2F37BF;
            border-radius: 0 0 0 17px ;
        }
    }

    .Col-buttonRight {
        .ant-btn {
            border-radius: 0 0 17px 0px;
        }
    }
    .Col-buttonRight-2 {
        .ant-btn {
            border-radius: 0 0 17px 17px;
        }
    }

  }
    
    
  .form-web-modal {
    border-radius: 20px;
    overflow: hidden;
  }
 .successCreate {
    font-size: 20px;
      color: #3F5E80;
      font-weight: 500;
      line-height: 24px;
}
 .description {
    font-size: 16px;
      color: #3F5E80;
      font-weight: 500;
      line-height: 24px;
}

  .remove-bottom-margin {
    margin-bottom: 0px;
  }
    padding-top: 36px
`;

export default Modal;
