import { Button, Col, ConfigProvider, DatePicker, Divider, Image, notification, Popover, Row, Select, Skeleton, Tag } from 'antd';
import es from 'antd/lib/locale/es_ES';
import ModalS from 'components/UI/ModalS';
import ModalsRsvp from 'components/UI/ModalsRsvp';
import format from 'date-fns/format';
import { es as datefnsES } from 'date-fns/locale';
import GoogleMapReact from 'google-map-react';
import moment from 'moment';
import 'moment/locale/es';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useLocation } from 'react-router-dom';
import { ReactComponent as IcEdit } from '../../assets/ic-edit.svg';
import TimeGrid from '../../components/UI/TimeGrid';
import { canUseCoupon, getIdentityCookie } from '../../services/auth';
import { getFieldsOnSede, getFieldTarifas, getGeolocationViaIP, getInfoCoupon, getSedesOnCentro } from '../../services/http';
import { useMediaQuery } from '../../shared/utils';
import { AntdCard, CardMap, Content, ContentDate, ContentDetailsCenter, Header, Layout } from './HomeCenter.style';

moment.locale('es');

const { Option } = Select;
const dateFormat = 'DD MMM YYYY';

const ContentPopover = ({ infoSede, infoLogo }) => {
  return (
    <CardMap>
      <Col span={8} className='Col-ImagField'>
        <Image width={78} height={78} preview={false} src={infoLogo} />
      </Col>
      <Col span={16} className='Col-infoCard'>
        <p className='Title-sede'>{infoSede.nombresede}</p>
        {/*  <p className='Inf-distance'>{`${infoSede.distance.toFixed(2)} km`}</p> */}
        <p className='Inf-direction'>
          <a rel='noreferrer' target='_blank' href={`https://www.google.com/maps/search/?api=1&query=${infoSede.latitud}%2C${infoSede.longitud}`}>
            {infoSede.direccion}
          </a>
        </p>
      </Col>
    </CardMap>
  );
};

const SedeLocation = ({ data, infoLogo }) => (
  <Popover visible content={() => <ContentPopover infoSede={data} infoLogo={infoLogo} />} overlayClassName='stylePopover'>
    <div className='styleMarker' />
  </Popover>
);

const HomeCenter = () => {
  const today = moment();

  const [sedes, setSedes] = useState([]);
  const [selectedSede, setSelectedSede] = useState({});
  const [fields, setFields] = useState([]);
  const [fieldImages, setFieldImages] = useState([]);
  const [selectedField, setSelectedField] = useState({});
  const [selectedDate, setselectedDate] = useState();
  const [timeGrid, setTimeGrid] = useState([]);
  const [rsvpInfo, setRsvpInfo] = useState({});
  const [fetchingSedes, setFetchingSedes] = useState(true);
  const [fetchingFields, setFetchingFields] = useState(true);
  const [fetchingTarifaData, setFetchingTarifaData] = useState(true);
  const location = useLocation();
  const isDesktop = useMediaQuery('(min-width: 609px)');
  const [showModalAuth, setShowModalAuth] = useState(false);
  const [showModalRsvp, setShowModalRsvp] = useState(false);

  const [showFixed, setShowFixed] = useState(false);

  //************ estado para cupon ************//
  const [cuponInfo, setCuponInfo] = useState({});

  const calculateDiscount = (monto, discountValue, porcentual) => {
    if (porcentual) {
      const montoFinal = monto - monto * (discountValue / 100);
      return parseFloat(montoFinal);
    } else {
      return parseFloat(monto - discountValue);
    }
  };

  const disabledDate = (current) => {
    // Deshabilitar los días pasados al actual
    return current && current < moment(today).set('day', moment(today).get('day') - 1);
  };

  const updateSelectedRsvp = (timeStart, timeEnd, monto) => {
    if (timeStart && timeEnd && monto) {
      const timestamprsvp = timeStart * 1000;
      const timestamprsvpend = timeEnd * 1000;
      //setPreselectedDateTime(null);
      setRsvpInfo((prev) => ({
        timestamprsvp,
        timestamprsvpend,
        monto,
      }));
    } else {
      setRsvpInfo(null);
      //setPreselectedDateTime(null);
    }
    //console.log({ timeStart, timeEnd, monto });
  };

  const updateSelectedDate = (selected) => {
    setselectedDate(selected);
    requestTarifa(selectedField.idcancha, selected.format('YYYY-MM-DD'), selectedField.country);
  };

  // Caso limpio
  // 1 Solicito Latitud y Longitud - check
  // 2 Solicito las sedes en el centro -> Se llena el select de sedes - check
  // 3 Se establece la sede 0 y se buscan las canchas -> Se llena el select de canchas
  // 4 Se establece la cancha 0

  const geoLocationRequestViaIP = async () => {
    try {
      const request = await getGeolocationViaIP();
      if (request.result) {
        const { geodata } = request;
        return {
          latitude: geodata.latitude,
          longitude: geodata.longitude,
        };
      } else {
        throw new Error('No geolocation');
      }
    } catch (error) {
      //console.log('error geoLocationRequestViaIP', error);
    }
  };

  const requestSedesOnCentro = async (idcentro, latitude, longitude) => {
    try {
      setFetchingSedes(true);
      const response = await getSedesOnCentro(idcentro, latitude, longitude);
      //debugger;
      const { cod, data } = response;
      if (cod === 1000) {
        setSedes(data);
        setFetchingSedes(false);
        return data;
      } else {
        throw new Error(`${cod}`);
      }
    } catch (error) {
      //console.log('error requestField: ', error);
      if (error.message === '1003') {
        notification.warning({
          message: 'No hay información del centro indicado',
          description: 'Al parecer este centro no existe en nuestros registros, por favor intenta nuevamente',
          duration: 3,
        });
      } else {
        notification.warning({
          message: 'No podemos hacer contacto con el servidor iCancha',
          description: 'Ocurrió un error intentando obtener la información del centro del servidor, si el problema persiste por favor contacta a soporte en info@icancha.com',
          duration: 3,
        });
      }
    }
  };

  const requestFieldsOnSede = async (idsede) => {
    try {
      setFetchingFields(true);
      const response = await getFieldsOnSede(idsede);
      const { cod, data } = response;
      if (cod === 1000) {
        setFields(data);
        setSelectedField(data[0]);
        setFetchingFields(false);
        return data;
      } else {
        throw new Error(`${cod}`);
      }
    } catch (error) {
      //console.log('error requestField: ', error);
      if (error.message === '1003') {
        notification.warning({
          message: 'No hay información de las canchas',
          description: 'Al parecer esta sede no posee canchas en nuestros registros, por favor intenta nuevamente',
          duration: 3,
        });
      } else {
        notification.warning({
          message: 'No podemos hacer contacto con el servidor iCancha',
          description: 'Ocurrió un error intentando obtener la información de las canchas del servidor, si el problema persiste por favor contacta a soporte en info@icancha.com',
          duration: 3,
        });
      }
    }
  };

  const requestTarifa = async (idcancha, date, country) => {
    try {
      setFetchingTarifaData(true);
      const response = await getFieldTarifas(idcancha, date, country);
      const { cod, data } = response;
      if (cod === 1000) {
        setTimeGrid(data);
        setFetchingTarifaData(false);
      } else {
        throw new Error(`${cod}`);
      }
    } catch (error) {
      //console.log('error requestTarifa: ', error);
      if (error.message === '1003') {
        notification.warning({
          message: 'No hay tarifas en la cancha indicada',
          description: 'Al parecer esta cancha no presente tarifas registradas, por favor intenta nuevamente',
          duration: 3,
        });
      } else {
        notification.warning({
          message: 'No podemos hacer contacto con el servidor iCancha',
          description: 'Ocurrió un error intentando obtener la información de la cancha del servidor, si el problema persiste por favor contacta a soporte en info@icancha.com',
          duration: 3,
        });
      }
    }
  };

  //// info del coupon /////
  const resquestInfoCoupon = async (coupon) => {
    try {
      if (!selectedField.idcancha) return;
      const response = await getInfoCoupon(coupon);
      //console.log({ response, selectedField });
      const { cod, data } = response;
      if (cod === 1000) {
        const sessionExist = !!getIdentityCookie();
        const canUse = await canUseCoupon(data.restrictions, selectedField.idcentro, selectedField.idcancha, selectedField.idsede, sessionExist);
        //console.log({ canUse });
        if (canUse) {
          setCuponInfo({ ...data, value: parseFloat(data.value) });
          return data;
        } else {
          setCuponInfo({});
          return;
        }
      } else {
        throw new Error(`${cod}`);
      }
    } catch (error) {
      setCuponInfo({});
      console.log(` error ${error}`);
    }
  };
  ////////////////////////////

  const changeField = (idfield) => {
    setTimeGrid([]);
    setFetchingTarifaData(true);
    setFetchingFields(true);
    setRsvpInfo(null);
    const fieldData = fields.filter((field) => field.idcancha === idfield);
    setSelectedField(fieldData[0]);
    const preparedImgs = fieldData[0].imagenes.map((imgData) => ({ original: imgData.url, thumbnail: imgData.url }));
    setFieldImages(preparedImgs);
    //console.log({ selectedDate });
    requestTarifa(fieldData[0].idcancha, selectedDate.format('YYYY-MM-DD'), fieldData[0].country);
    setTimeout(() => {
      setFetchingFields(false);
    }, 500);
    //console.log({ fieldData });
  };

  const changeSede = async (idsede) => {
    try {
      const sedeData = sedes.filter((sede) => sede.idsede === idsede);
      setFetchingTarifaData(true);
      setFetchingSedes(true);
      setRsvpInfo(null);
      setSelectedSede(sedeData[0]);
      const parsedDate = selectedDate.format('YYYY-MM-DD');
      const fieldsOnSede = await requestFieldsOnSede(sedeData[0].idsede);
      await requestTarifa(fieldsOnSede[0].idcancha, parsedDate, fieldsOnSede[0].country);
      const preparedImgs = fieldsOnSede[0].imagenes.map((imgData) => ({ original: imgData.url, thumbnail: imgData.url }));
      setFieldImages(preparedImgs);
      setTimeout(() => {
        setFetchingSedes(false);
      }, 500);
      //console.log({ sedeData });
    } catch (error) {
      //console.log('error changeSede', error);
    }
  };

  const reservar = () => {
    try {
      const sessionExist = getIdentityCookie();
      if (sessionExist) {
        // Colocar modal reserva
        setShowModalRsvp(true);
        //console.log(`Puede reservar ${sessionExist}`);
      } else {
        setShowModalAuth(true);
      }
      //setShowModalAuth(true);
    } catch (error) {
      //console.log('Error reservar', error);
    }
    //console.log(rsvpInfo);
  };

  const modalRsvpCallBack = (showModal) => {
    setShowModalRsvp(showModal);
  };

  const modalAuthCallBack = (show) => {
    setShowModalAuth(show);
    //window.location.reload();
  };

  useEffect(() => {
    try {
      setselectedDate(today);
      const idcentro = process.env.REACT_APP_CENTER_KEY;
      //console.log(idcentro);

      if (idcentro) {
        const initialLoad = async (idcentro) => {
          const parsedToday = moment().format('YYYY-MM-DD');
          const geolocation = await geoLocationRequestViaIP();
          const sedesOnCentro = await requestSedesOnCentro(idcentro, geolocation.latitude, geolocation.longitude);
          setSelectedSede(sedesOnCentro[0]);
          const fieldsOnSede = await requestFieldsOnSede(sedesOnCentro[0].idsede);
          await requestTarifa(fieldsOnSede[0].idcancha, parsedToday, fieldsOnSede[0].country);
          const preparedImgs = fieldsOnSede[0].imagenes.map((imgData) => ({ original: imgData.url, thumbnail: imgData.url }));
          setFieldImages(preparedImgs);
        };
        initialLoad(idcentro);
      } else {
        throw new Error('No exist idcentro');
      }
    } catch (error) {
      //console.log('error', error);
    }
  }, []);

  useEffect(() => {
    const onScroll = () => {
      const newShowFixed = window.scrollY > 180;
      showFixed !== newShowFixed && setShowFixed(newShowFixed);
    };
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  });

  useEffect(() => {
    setCuponInfo({});
    if (selectedField) {
      const str = location.search;
      if (str.includes('coupon')) {
        let query = new URLSearchParams(str);
        const coupon = query.get('coupon');
        resquestInfoCoupon(coupon);
      }
    }
  }, [selectedField.idcancha]);

  console.log({ selectedSede })
  return (
    <Layout>
      <Helmet>
        <title>{selectedSede && `${selectedSede.nombresede ? selectedSede.nombresede : ''} - iCancha`}</title>
      </Helmet>
      <Header>
        <Row style={{ maxWidth: '1440px', margin: 'auto' }}>
          <Col xs={6} sm={5} md={5} lg={6} xl={6} xxl={6} className='Col-left-logo'>
            <Image preview={false} width={78} height={78} src={selectedField.logosede} />
          </Col>
        </Row>
      </Header>
      <Content>
        <ConfigProvider locale={es}>
          <ContentDetailsCenter fixed={showFixed ? showFixed : undefined} style={{ justifyContent: 'center', maxWidth: '1440px', margin: 'auto' }}>
            <ModalS reserveButton modalRsvpCallBack={setShowModalRsvp} showModal={showModalAuth} modalAuthCallBack={modalAuthCallBack} modalcenter />
            <ModalsRsvp
              showModal={showModalRsvp}
              modalRsvpCallBack={modalRsvpCallBack}
              resquestInfoCoupon={resquestInfoCoupon}
              fieldData={selectedField}
              rsvpInfo={rsvpInfo}
              selectedDate={selectedDate}
              cuponInfo={cuponInfo}
              calculateDiscount={calculateDiscount}
              modalcenter
              reservationWitOuthCreditMemo={selectedSede?.reservasinpago}
              reservationWithCreditMemo={selectedSede?.reservaconpago}
            />
            <Col className='Col-Content--infoCenter' xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
              {isDesktop && (
                <Row className='Row-Select--menu'>
                  <Col span={14}>
                    {fetchingSedes ? (
                      <Skeleton.Input style={{ width: 360 }} active />
                    ) : (
                      <>
                        <p className='Title-Sede'>{selectedSede && selectedSede.nombresede}</p>
                        <p className='Direction-Sede'>{selectedSede && selectedSede.direccion}</p>
                      </>
                    )}
                  </Col>
                  <Col span={10} className='Col-Content--Select' style={{ display: 'inline-grid', zIndex: '10' }}>
                    <label>Sedes Centro Deportivo</label>
                    {Object.keys(selectedSede).length > 0 && (
                      <Select
                        style={{
                          width: '100%',
                        }}
                        defaultValue={selectedSede.idsede}
                        className='Select-Centro'
                        onChange={changeSede}>
                        {sedes.length > 0 && sedes.map((sede) => <Option value={sede.idsede} key={sede.idsede}>{sede.nombresede}</Option>)}
                      </Select>
                    )}
                  </Col>
                </Row>
              )}
              {!isDesktop ? (
                fetchingSedes ? (
                  <>
                    <Row style={{ background: '#ffff', padding: '20px 20px' }}>
                      <Skeleton.Input style={{ width: 360 }} active />
                      <Skeleton.Input style={{ width: 360, marginTop: 4 }} active />
                    </Row>
                  </>
                ) : (
                  <>
                    <Row style={{ background: '#ffff', padding: '20px 20px' }}>
                      <Col className='Col-Content--Select' span={24} style={{ display: 'inline-grid', zIndex: '10' }}>
                        <label>Sedes Centro Deportivo</label>
                        {Object.keys(selectedSede).length > 0 && (
                          <Select defaultValue={selectedSede.idsede} className='Select-Centro' onChange={changeSede}>
                            {sedes.length > 0 && sedes.map((sede) => <Option value={sede.idsede} key={sede.idsede}>{sede.nombresede}</Option>)}
                          </Select>
                        )}
                      </Col>
                      <Col className='Col-Content--Select' span={24} style={{ display: 'inline-grid', zIndex: '10' }}>
                        {fetchingFields ? (
                          <Skeleton.Input style={{ width: 360 }} active />
                        ) : (
                          <>
                            <label>Más Canchas Disponibles</label>
                            <Select defaultValue={selectedField && selectedField.idcancha} onChange={changeField}>
                              {fields.length > 0 && fields.map((field) => <Option value={field.idcancha} key={field.idcancha}>{field.nombrecancha}</Option>)}
                            </Select>
                          </>
                        )}
                      </Col>
                    </Row>
                  </>
                )
              ) : (
                <>
                  <Row style={{ background: '#ffff', padding: '0px 20px', height: '297px' }}>
                    <Col span={24}>
                      <AntdCard>
                        <Row>
                          {fetchingFields ? (
                            <>
                              <Col span={8} className='Col-ImagField'>
                                <Skeleton.Image style={{ height: '200px', width: '300px', margin: '40px 0 ' }} active />
                              </Col>
                              <Col span={11} className='Col-infoCard'>
                                <Skeleton paragraph={{ rows: 3 }} active />
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col span={8} className='Col-ImagField'>
                                <ImageGallery items={fieldImages} showFullscreenButton={false} showPlayButton={false} />
                              </Col>
                              <Col span={16} className='Col-Content---info'>
                                <Row className='Row-Title--Info'>
                                  <Col className='Col-Title--Info' span={9}></Col>
                                  <Col span={15} style={{ display: 'inline-grid', zIndex: '10' }}>
                                    {fetchingFields ? (
                                      <Skeleton.Input style={{ width: 300 }} active />
                                    ) : (
                                      <>
                                        <label>Más Canchas Disponibles</label>
                                        <Select
                                          style={{
                                            width: '100%',
                                            height: '45px',
                                          }}
                                          defaultValue={selectedField && selectedField.idcancha}
                                          className='Select-Inf--Center'
                                          onChange={changeField}>
                                          {fields.length > 0 && fields.map((field) => <Option value={field.idcancha} key={field.idcancha}>{field.nombrecancha}</Option>)}
                                        </Select>
                                      </>
                                    )}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={14} className='Col-infoCard'>
                                    <p className='Title-field'>{selectedField && selectedField.nombrecancha}</p>
                                    <p className='Type-field'>
                                      Tipo de Cancha
                                      <Tag style={{ background: '#FBB800', borderColor: '#FBB800', borderRadius: '10px', color: '#020609' }}>{selectedField && selectedField.clasificacion}</Tag>
                                    </p>
                                    <p className='Title-atrib'>Atributos de la Cancha</p>
                                    <div className='div-tag'>
                                      {/*<Divider orientation='left' style={{ padding: '0px', margin: '0px' }}>*/}
                                      {selectedField &&
                                        selectedField.atributos.length > 0 &&
                                        selectedField.atributos.map((atributo) => (
                                          <Tag key={atributo.id} color='#EDEDED'>
                                            {atributo.nombre}
                                          </Tag>
                                        ))}
                                      {/*</Divider>*/}
                                    </div>
                                  </Col>
                                  <Col span={10} className='Col-PriceRsvp'>
                                    {rsvpInfo && rsvpInfo.monto && (
                                      <>
                                        {cuponInfo.valid ? (
                                          <>
                                            <Tag color='#FFDF00'>Descuento {cuponInfo.percent ? `${cuponInfo.value}%` : `$ ${cuponInfo.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</Tag>
                                            <p className='text-price cupon'>Valor Reserva</p>
                                            <p className='real-amount'>{`$ ${rsvpInfo.monto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</p>
                                            <p className='num-price amount'>{`$ ${calculateDiscount(rsvpInfo.monto, cuponInfo.value, cuponInfo.percent)
                                              .toFixed(2)
                                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</p>
                                          </>
                                        ) : (
                                          <>
                                            <p className='text-price'>Valor Reserva</p>
                                            <p className='num-price'>{`$ ${rsvpInfo.monto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</p>
                                          </>
                                        )}
                                        <Button type='Primary' onClick={reservar}>
                                          Reservar
                                        </Button>
                                      </>
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                            </>
                          )}
                        </Row>
                      </AntdCard>
                    </Col>
                  </Row>
                </>
              )}
              <Row className='Row-Content--InfoCentro'>
                <Col span={24}>
                  {fetchingSedes ? (
                    <Skeleton paragraph={{ rows: 1 }} active />
                  ) : (
                    <>
                      <p style={{ fontSize: '18px', fontWeight: '700', color: '#1E3799', fontFamily: '"Roboto", sans-serif', marginBottom: '10px' }}>Información Adicional</p>
                      <p>{selectedSede && selectedSede.descripcion}</p>
                    </>
                  )}
                </Col>
                <Col span={24}>
                  {fetchingSedes ? (
                    <Skeleton paragraph={{ rows: 1 }} active />
                  ) : (
                    <>
                      <p style={{ fontSize: '18px', fontWeight: '700', color: '#1E3799', fontFamily: '"Roboto", sans-serif', marginBottom: '10px' }}>Servicios de la Sede</p>
                      <p>
                        {selectedSede &&
                          Object.keys(selectedSede).length > 0 &&
                          selectedSede.servicios.length > 0 &&
                          selectedSede.servicios.map((servicio, index) => {
                            if (index < 1) {
                              return <span key={servicio.idservicio}>{servicio.nombre}</span>;
                            } else {
                              return <span key={servicio.idservicio}> - {servicio.nombre}</span>;
                            }
                          })}
                      </p>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10} style={{ background: '#EDEDED', maxHeight: 'calc(100vh - 80px)' }}>
              <ContentDate>
                <Row className='Row-Content--timersvp'>
                  {!isDesktop ? (
                    <>
                      <div id='editTime' />
                      <Col className='Col-title--timersvp' span={24}>
                        <p>Consulta y cambia los Horarios</p>
                        <p>disponibles para tu reserva</p>
                      </Col>
                      <Col span={24} style={{ textAlign: 'end' }}>
                        {fetchingFields ? (
                          <>
                            <Skeleton.Input active='true' />
                          </>
                        ) : (
                          <>
                            <label style={{ position: 'absolute', zIndex: '10', fontSize: '12px', top: '6px', left: '21px', fontFamily: '"Roboto", sans-serif', fontWeight: '500', color: '#9DAAB8' }}>
                              Cambiar Fecha
                            </label>
                            <DatePicker
                              inputReadOnly
                              placeholder=''
                              defaultValue={moment(selectedDate, dateFormat)}
                              allowClear={false}
                              format={dateFormat}
                              disabledDate={disabledDate}
                              onChange={updateSelectedDate}
                              style={{ height: '45px', borderRadius: '10px', background: '#FFFF ', borderColor: '#DFE8F1' }}
                              showToday={false}
                            />
                          </>
                        )}
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col className='Col-title--timersvp' span={17}>
                        <p>Consulta los Horarios</p>
                        <p>Disponibilidad para tu reserva</p>
                      </Col>
                      <Col span={7} style={{ textAlign: 'end' }}>
                        {fetchingFields ? (
                          <>
                            <Skeleton.Input active='true' />
                          </>
                        ) : (
                          <>
                            <label style={{ position: 'absolute', zIndex: '10', fontSize: '12px', top: '6px', left: '21px', fontFamily: '"Roboto", sans-serif', fontWeight: '500', color: '#9DAAB8' }}>
                              Fecha
                            </label>
                            <DatePicker
                              inputReadOnly
                              placeholder=''
                              defaultValue={moment(selectedDate, dateFormat)}
                              allowClear={false}
                              format={dateFormat}
                              disabledDate={disabledDate}
                              onChange={updateSelectedDate}
                              style={{ height: '45px', borderRadius: '10px', background: '#DFE8F1 ', borderColor: '#DFE8F1' }}
                              showToday={false}
                            />
                          </>
                        )}
                      </Col>
                    </>
                  )}
                </Row>
                <TimeGrid loading={fetchingTarifaData} manageSelection={updateSelectedRsvp} fieldTimeGrid={timeGrid} currentDate={selectedDate} />
                {!isDesktop &&
                  (fetchingFields ? (
                    <Row style={{ background: '#ffff', padding: '0px 20px' }}>
                      <Col span={24}>
                        <AntdCard>
                          <Row>
                            <Col span={12} className='Col-infoCard'>
                              <Skeleton paragraph={{ rows: 2 }} active />
                            </Col>
                            <Col span={12} className='Col-PriceRsvp'></Col>
                            <Col span={24} className='Col-ImagField' style={{ textAlign: 'center' }}>
                              <Skeleton.Image style={{ height: '200px', width: '300px', margin: 'auto' }} active />
                            </Col>
                            <Col className='Col-infoCard' style={{ marginTop: '56px' }}>
                              <Skeleton paragraph={{ rows: 2 }} active />
                            </Col>
                            <Col span={24}>
                              <Skeleton paragraph={{ rows: 2 }} active />
                            </Col>
                          </Row>
                        </AntdCard>
                      </Col>
                    </Row>
                  ) : (
                    <>
                      <Row style={{ background: '#ffff', padding: '0px 20px' }}>
                        <Col span={24}>
                          <AntdCard>
                            <Row>
                              <Col span={12} className='Col-infoCard'>
                                <p className='Title-field'>{selectedField && selectedField.nombrecancha}</p>
                                <p className='Title-sede'>{selectedField && selectedField.nombresede}</p>
                                <p className='Inf-direction'>{selectedField && selectedField.direccion}</p>
                              </Col>
                              <Col span={12} className='Col-PriceRsvp'>
                                {cuponInfo.valid && (
                                  <Tag color='#FFDF00'>Descuento {cuponInfo.percent ? `${cuponInfo.value}%` : `$ ${cuponInfo.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</Tag>
                                )}
                              </Col>
                              {fieldImages.length > 0 && (
                                <Col span={24} className='Col-ImagField'>
                                  <ImageGallery items={fieldImages} showFullscreenButton={false} showPlayButton={false} showThumbnails={false} showNav={false} showBullets />
                                  <div
                                    style={{
                                      background: '#FBB800',
                                      width: '65px',
                                      height: '26px',
                                      position: 'absolute',
                                      zIndex: '2',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      borderRadius: '10px',
                                      top: '202px',
                                      right: '10px',
                                    }}>
                                    <p style={{ color: '#1B1464', fontFamily: '"Roboto",  sans-serif', fontSize: '10px', fontWeight: '700', display: 'grid', alignContent: 'center', margin: '0px' }}>
                                      {selectedField && selectedField.clasificacionsiglas}
                                    </p>
                                  </div>
                                  <small style={{ color: '#e3dfdf' }}>Versión APP: {process.env.REACT_APP_VERSION}</small>
                                </Col>
                              )}

                              <Col className='Col-infoCard' style={{ marginTop: '56px' }}>
                                <p className=' ' style={{ fontSize: '18px', fontWeight: '700', color: 'black', marginBottom: '0px' }}>
                                  Atributos de la Cancha
                                </p>
                                <div className='div-tag'>
                                  <Divider orientation='left' style={{ padding: '0px', margin: '0px' }}>
                                    {selectedField &&
                                      selectedField.atributos.length > 0 &&
                                      selectedField.atributos.map((atributo) => (
                                        <Tag key={atributo.id} color='#EDEDED' style={{ marginLeft: '0px', fontSize: '13px', color: '#616FA1', height: '27px', padding: '3px 10px' }}>
                                          {atributo.nombre}
                                        </Tag>
                                      ))}
                                  </Divider>
                                </div>
                              </Col>
                              <Col className='Col-content--infrsvp' span={24}>
                                {rsvpInfo && Object.keys(rsvpInfo).length > 0 && (
                                  <>
                                    <p className='p-title'>Estás reservando en este horario</p>
                                    <Row>
                                      <Col span={22}>
                                        <p style={{ fontSize: '18px', color: '#1B1464', fontWeight: '500', marginBottom: '0px' }}>
                                          {`${format(new Date(selectedDate), 'dd/MMM/YYY', { locale: datefnsES })} - ${format(new Date(rsvpInfo.timestamprsvp), 'hh:mm aaa', {
                                            locale: datefnsES,
                                          })} a ${format(new Date(rsvpInfo.timestamprsvpend), 'hh:mm aaa', { locale: datefnsES })}`}
                                        </p>
                                      </Col>
                                      <Col span={2} className='Col-IcEdit'>
                                        <a href='#editTime'>
                                          <IcEdit />
                                        </a>
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </Col>
                            </Row>
                          </AntdCard>
                        </Col>
                      </Row>
                      {rsvpInfo && rsvpInfo.monto && (
                        <Row className='Row-info--reserva'>
                          <>
                            <Col span={14} className='Col-infotext'>
                              <p className='p-title'>Valor Reserva</p>
                              {cuponInfo.valid ? (
                                <>
                                  <p className='real-amount'>{`$ ${rsvpInfo.monto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</p>
                                  <p className='p-price amount'>{`$ ${calculateDiscount(rsvpInfo.monto, cuponInfo.value, cuponInfo.percent)
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</p>
                                </>
                              ) : (
                                <p className='p-price'>{`$ ${rsvpInfo.monto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</p>
                              )}
                            </Col>
                            <Col className='Col-contentButton' span={10}>
                              <Button onClick={reservar}>RESERVAR</Button>
                            </Col>
                          </>
                        </Row>
                      )}
                    </>
                  ))}
                <Row className='Row-ContentMap'>
                  {fetchingFields ? (
                    <div style={{ width: '100%' }}>
                      <Skeleton.Button active='true' />
                    </div>
                  ) : (
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
                      options={{
                        fullscreenControl: false,
                        draggable: false, //evita mover el mapa
                        zoomControl: false,
                        keyboardShortcuts: false, // desactiva control de teclado
                        mapTypeControl: false,
                        mapId: '34ec851272486dad',
                      }}
                      defaultCenter={{
                        lat: selectedSede.latitud, // coordenadas del centro correspondiente
                        lng: selectedSede.longitud,
                      }}
                      defaultZoom={15}>
                      <SedeLocation data={selectedSede} infoLogo={selectedField.logosede} lat={selectedSede.latitud} lng={selectedSede.longitud} />
                    </GoogleMapReact>
                  )}
                  <small style={{ color: '#979797' }}>Versión APP: {process.env.REACT_APP_VERSION}</small>
                </Row>
              </ContentDate>
            </Col>
          </ContentDetailsCenter>
        </ConfigProvider>
      </Content>
    </Layout>
  );
};

export default HomeCenter;
